import React from "react";
import { Page } from "../components/Page";
import { Title } from "../components/Title";
import { Content } from "../components/Content";
import { Image } from "../components/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import tip1 from "../images/tip-1.jpg";
import tip2 from "../images/tip-2.jpg";
import tip3 from "../images/tip-3.jpg";
import tip4 from "../images/tip-4.jpg";
import tip5 from "../images/tip-5.jpg";
import tip6 from "../images/tip-6.jpg";
import tip7 from "../images/tip-7.jpg";
import tip8 from "../images/tip-8.jpg";
import tip9 from "../images/tip-9.jpg";
import tip10 from "../images/tip-10.jpg";
import tip11 from "../images/tip-11.jpg";
import tip12 from "../images/tip-12.jpg";

const Center = ({ children }) => (
  <Content style={{ textAlign: "center" }}>{children}</Content>
);

const TipsPage = () => (
  <Page page="Talvihoitovinkit">
    <Row>
      <Col>
        <Title>12 vinkkiä talvihoitoon</Title>
      </Col>
    </Row>

    <Row>
      <Col md={4}>
        <Image
          src={tip1}
          alt="Esimerkkikuva ahtaasta mainoskyltin sijoittamisesta"
        />

        <Center>1. Älä sijoita mainoskylttejä kapeisiin paikkoihin.</Center>
      </Col>

      <Col md={4}>
        <Image
          src={tip2}
          alt="Esimerkkikuva liukkaasta ja epätasaisesta tien pinnasta"
        />

        <Center>
          2. Jäätynyt ja epätasainen pinta on vaarallisen liukas, koska
          hiekoitussepeli valuu kuoppakohtiin. Nopea ja aikainen auraus vähentää
          hiekoituksen tarvetta niin, että kokonaiskustannukset eivät kasva,
          mutta laatutaso on merkittävästi parempi. Ja todennäköisesti
          säästytään sairaalareissuilta.
        </Center>
      </Col>

      <Col md={4}>
        <Image src={tip3} alt="Esimerkkikuva liukuesteiden käytöstä" />

        <Center>
          3. On hienoa, että yhä useampi käyttää jalkineissaan liukuesteitä.
          Liikekiinteistöjen tulee huolehtia, että eteistiloissa on
          liukuesteiden väliaikaista poistamista varten tuoleja.
        </Center>
      </Col>

      <Col md={4}>
        <Image src={tip4} alt="Esimerkkikuva paakkuuntuneesta lumesta" />

        <Center>
          4. Yritä aina ehtiä auraraaman jalkakäytävä ennen kuin lumi
          paakkuuntuu ja lämpötilan mahdollisesti laskiessa jäätyy kovaksi.
          Nopea ja aikainen auraus vähentää hiekoituksen tarvetta niin, että
          kokonaiskustannukset eivät kasva, mutta laatutaso on merkittävästi
          parempi. Ja todennäköisesti säästytään sairaalareissuilta.
        </Center>
      </Col>

      <Col md={4}>
        <Image
          src={tip5}
          alt="Esimerkkikuva tasaterällä liukkaaksi auratusta kadusta"
        />

        <Center>
          5. Yritä päästä naapurikiinteistöjen kanssa tällaiseen yhtäaikaiseen
          hoitoon ja samanlaiseen laatuun. Esimerkiksi lumisade voi peittää työn
          jäljen, jolloin laadun äkillinen muutos tulee yllättäen ja
          mahdollisesti kohtalokkain seurauksin. Aurauksessa on käytettävä
          pintaa karhentavaa terää. Tasaterän käyttö tekee jalkakäytävän
          pinnasta liukkaan.
        </Center>
      </Col>

      <Col md={4}>
        <Image
          src={tip6}
          alt="Esimerkkikuva jäätyneestä valumavedestä jalkakäytävällä"
        />

        <Center>
          6. Pahoja paikkoja voi myös syntyä kattolumien keväällä sulaessa ja
          niiden valumien yöllä jäätyessä.
        </Center>
      </Col>

      <Col md={4}>
        <Image
          src={tip7}
          alt="Esimerkkikuva jäätyneestä auton sisäänajoluiskasta"
        />

        <Center>
          7. Myös kiinteistöjen sisäänajoluiskat saattavat autojen sutimisen
          johdosta tulla vaarallisen liukkaiksi.
        </Center>
      </Col>

      <Col md={4}>
        <Image
          src={tip8}
          alt="Esimerkkikuva jäätyneestä kynnyksestä katulämmityksen reunalla"
        />

        <Center>
          8. Vaarallisia "kynnyksiä" voi muodostua katulämmityksen reunakohtiin.
        </Center>
      </Col>

      <Col md={4}>
        <Image
          src={tip9}
          alt="Esimerkkikuva vapaasta kulkuväylästä asuinkiinteistössä"
        />

        <Center>
          9. Siirtoajojen välttämiseksi pyrkikää naapurienne kanssa sopimaan
          hoito-osuuksien vaihtokaupoista.
        </Center>
      </Col>

      <Col md={4}>
        <Image
          src={tip10}
          alt="Esimerkkikuva suojatien eteen kertyneestä lumesta"
        />

        <Center>
          10. Muista, että jalankulkijoiden on päästävä esteettömästi myös
          suojateille ja niiden valonapeille.
        </Center>
      </Col>

      <Col md={4}>
        <Image
          src={tip11}
          alt="Esimerkkikuva väliaikaisten lumikasojen näkyvyyttä haittaavasta sijoittelusta"
        />

        <Center>
          11. Vältä sijoittamasta väliaikaisiakaan lumikasoja suojateiden
          näkemäesteeksi. Mieluummin vähäksi aikaa menetetty parkkipaikka kuin
          kohtalokas jalankulkijaonnettomuus.
        </Center>
      </Col>

      <Col md={4}>
        <Image
          src={tip12}
          alt="Esimerkkikuva hyvin hoidetusta risteysalueesta"
        />

        <Center>
          12. Näkemäesteitä ei ole eikä myöskään suojateiden käyttöä estäviä
          valleja - loistavasti hoidettu risteysalue!
        </Center>
      </Col>
    </Row>
  </Page>
);

export default TipsPage;
